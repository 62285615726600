<template>
  <div class=container>
<table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">Global Measures of Coherence for Edge Detector Evaluation</font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><em>
              </em><div align="left">
                <p align="left">                These pages contain the databases and evaluation code required to run the experiments described in the paper:
              
              </p><p>
</p><blockquote>
<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Global Measures of Coherence for Edge Detector Evaluation,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">S. Baker and S.K. Nayar,<br data-v-0ff51178=""></span><span data-v-0ff51178="">IEEE Conference on Computer Vision and Pattern Recognition (CVPR),<br data-v-0ff51178=""></span><span data-v-0ff51178="">Vol. 2, pp. 373-379, Jun. 1999<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Baker_CVPR99.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=112" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <span data-v-0ff51178=""><a href="projects/categories/project?cid=Visual+Recognition+&amp;pid=Parametric+Feature+Detection" class="url" data-v-0ff51178=""> [Project Page]</a></span><br data-v-0ff51178=""></div>
</blockquote>
                <p> Becauase I am no longer at Columbia, I have not had as much time as I would have liked to make these evaluation tools as portable and easy to use as possible. If you encounter any problems trying to use my code, please contact me and I will try to help. E-mail: <a href="mailto:simonb@cs.cmu.edu">simonb@cs.cmu.edu</a>
                </p><p>
                </p><hr width="100%">
               <p class="style3"><u>
                Modifying Your Edge Detector</u></p>
                <p>The first thing you need to do use these evaluation tools is to modify your edge detector to comply with the following requirements:
              
                </p><dl>
                  <dt><b>Inputs:</b>
                  </dt><dd>(1) A standard grey-scale .pgm file.
                  </dd><dd>(2) The number of edges to detect.
                      <p>
                  </p></dd><dt><b>Command Line Interface:</b>
                  </dt><dd>Should be of the form:
                                        <p>
                                        </p><dl>
                                          <dl>
        sobel image.pgm #edges
                                          </dl>
                                        </dl>
                                        <p>
                  </p></dd><dt><b>Operation:</b>
                  </dt><dd>Your detector should <b>sort the detected</b> edges by their confidences (most confident first) and output as many of them as were requested in the command line.
                                                                            <p>
                  </p></dd><dt><b>Output:</b>
                  </dt><dd>For each detected edge, a line of ASCII text should be written to standard output. Each line consists of 4 real-valued fields, separated by spaces:
                                                                                                                                                    <dl>
                                                                                                                                                      <dl>
                                                                                                                                                        <dt>(1) The x-coordinate of the location of the edge.
                                                                                                                                                        </dt><dt>(2) The y-coordinate of the location of the edge.
                                                                                                                                                        </dt><dt>(3) The angle of orientation of the edge in degrees.
                                                                                                                                                        </dt><dt>(4) The confidence measure.
                                                                                                                                                      </dt></dl>
                                                                                                                                                    </dl>
                                                                                                                                                    <p>
                  </p></dd><dt><b>Coordinate Frame:</b>
                  </dt><dd>The location and orientation of the edges must be specified in the following coordinate frame:
                                                                                                                                                                                                                                                                                                    <dl>
                                                                                                                                                                                                                                                                                                      <dl>
                                                                                                                                                                                                                                                                                                        <dt>(1) The origin of the coordinate frame is the middle of the bottom-left pixel. 
                                                                                                                                                                                                                                                                                                        </dt><dt>(2) The distance between the centers of neighbouring pixels is one unit.
                                                                                                                                                                                                                                                                                                        </dt><dt>(3) The x-axis points to the right.
                                                                                                                                                                                                                                                                                                        </dt><dt>(4) The y-axis points upwards.
                                                                                                                                                                                                                                                                                                        </dt><dt>(5) Angles are measures anti-clockwise in degrees, and the zero direction is along the x-axis.
                                                                                                                                                                                                                                                                                                      </dt></dl>
                                                                                                                                                                                                                                                                                                    </dl>
                                                                                                                                                                                                                                                                                                    Of these requirements, the most important one is that the <b>origin is at the center of the bottom left-most pixel</b>.
      <p>
                  </p></dd><dt><b>Examples:</b>
                  </dt><dd>To help you fullfill these requirements, here are a few examples:
                                                                                                                                                                                                                                                                                                      <dl>
                                                                                                                                                                                                                                                                                                        <dl>
                                                                                                                                                                                                                                                                                                          <dt>(1) Example C++ code for a <a href="https://www1.cs.columbia.edu/CAVE/software/edge/sobel.cc"> sobel detector</a> which fullfills the requirements. Feel free to use the input/output parts of this example. Alternatively, use it as a template.
                                                                                                                                                                                                                                                                                                          </dt><dt>(2) An example .pgm image <a href="https://www1.cs.columbia.edu/CAVE/software/edge/d100.pgm"> d100.pgm</a> from one of the benchmarks
                                                                                                                                                                                                                                                                                                          </dt><dt>(3) The <a href="https://www1.cs.columbia.edu/CAVE/software/edge/out.txt"> output</a> written to standard output by this sobel detector on this image when called with the command line "sobel d100.pgm 100"
                                                                                                                                                                                                                                                                                                        </dt></dl>
                                                                                                                                                                                                                                                                                                      </dl>
                </dd></dl>
                <p>
                </p><hr width="100%">
                <p class="style3"><u>Evaluation Code</u></p>
                <p> The next thing to do is to down-load the <a href="https://www1.cs.columbia.edu/CAVE/software/edge/evaluation_code.tar.gz"> evaluation code</a> and compile it. If the Makefile does not work and you can't figure out how to modify it, compiling the programs by hand should be straightforward. They should compile with pretty much any C++ compiler. The only libraries they need is the standard i/o and math libraries. Once you have compiled the code, you will need to add the appropriate directory to your path.
              </p><p>There are 7 executables concerned with the evaluation of the edge detectors, and 2 concerned with averaging over multiple images. The 7 evaluation executables are:
                </p><dl>
                  <dl>
                    <dt>(1) gmc1_colinear_v1 - the first variant of the first (colinearity) global measure of coherence which uses single edges including their orientation
                    </dt><dt>(2) gmc1_colinear_v2 - the second variant of the first (colinearity) global measure of coherence which uses two edges and inores the orientations
                    </dt><dt>(3) gmc2_intersection - the second global measure of coherence, the one based on a common intersection point
                    </dt><dt>(4) gmc3_coparallel - the third global measure of coherence, the one based on the edges all be coparallel
                    </dt><dt>(5) gmc4_ellipse5 - the first variant of the fourth (ellipse) measure of coherence which uses 5 points on the ellipse and no tangency constraints
                    </dt><dt>(6) gmc4_ellipse4 - the second variant of the fourth (ellipse) measure of coherence which uses 4 points on the ellipse and one tangency constraint
                    </dt><dt>(7) gmc4_ellipse3 - the third variant of the fourth (ellipse) measure of coherence which uses 3 points on the ellipse and two tangency constraints
                  </dt></dl>
                </dl>
                <p>
                </p><p> All of the programs read from standard input and write to standard output. They have one command line argument, namely, the number of edges to work on. They can be combined with an edge detector in the following manner:
                </p><dl>
                  <dl>
                    <p> sobel d100.pgm 100 | gmc1_colinear_v1 100
                  </p></dl>
                </dl>
                <p> The output is a curve of the confidence measure against the global measure of coherence. An example of the output obtained using the above command line, and the sobel detector and image give above is contained in <a href="https://cave.cs.columbia.edu/old/software/edge/out2.txt"> this file</a>. The curve in the second column of this file is an example of one of those shown in Figure 3 of the paper.
  
              </p><p>The other 2 exectables are concerned with averaging over several images:
                </p><dl>
                  <dl>
                    <dt>(1) gmc_mean - used to average over several different images to create graphs like that in Figure 4 in the paper
                    </dt><dt>(2) gmc_diff - used to compare two detectors on the same image to produce graphs like that in Figure 5 in the paper
                  </dt></dl>
                </dl>
                <p> Hopefully, you should not need to touch these files.
  
              </p><p> Finally, the evaluation code contains a number of scripts which are used to put all the pieces together:
                </p><dl>
                  <dl>
                    <dt>(1) gmc_benchmark - this script takes 3 arguments: (1) the edge detector, (2) the evaluation program from one of the 7 above, and (3) the number of edges to apply it to. Called from within one of the bechmark database directories below it generates a curve like those in Figure 4 in the paper. Called with the command line
                        <p>
                        </p><dl>
                          <dl>
          gmc_benchmark sobel gmc1_colinear_v1 1000
            <p>
                          </p></dl>
                        </dl>
                        in the first bechmark database below it generates the <a href="https://www1.cs.columbia.edu/CAVE/software/edge/sobel_gmc1_colinear_v1_1000.res">curve</a> in the results directory. Column 2 of this file is the mean measure of coherence, and column 3 is the variance.
              </dt><dt>(2) gmc_comparison - this script takes 4 arguments: (1) the first edge detector, (2) the second edge detector, (3) the evaluation program from one of the 7 above, and (3) the number of edges to apply it to. Called from within one of the bechmark database directories below it generates a curve like those in Figure 5 in the paper. Called with the command line
                        <p>
                        </p><dl>
                          <dl>
          gmc_comparison sobel roberts gmc1_colinear_v1 1000
            <p>
                          </p></dl>
                        </dl>
                        in the first bechmark database below it generates the <a href="https://www1.cs.columbia.edu/CAVE/software/edge/sobel_roberts_gmc1_colinear_v1_1000.res">curve</a> in the results directory. Column 2 of this file is the mean relative measure of coherence, and column 3 is the variance.
            </dt></dl>
                </dl>
                <p>
                </p><p>
                </p><hr width="100%">
                <p class="style3"><u>Benchmark Databases</u></p>
                <p>There are 7 benchmark databases. Once downloaded and uncompressed, change directory into the benchmark directory so that the image, results, and tmp directories are visible. Then, either of the scripts gmc_benchmark or gmc_comparison can be called. Any edge detector can be used with any benchmark. However, only certain evaluation programs can be used with each benchmark. Similarly, each benchmark should be called with a different number of edges. For each benchmark, I described which evaluation programs can be used and give a guideline number of edges.
                </p>
                <dl>
                  <dl>
                    <dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark1.tar.gz"> Benchmark 1</a> - Use with either gmc1_colinear_v1 or gmc_colinear_v2 and around 1000 edges. Example use: "gmc_benchmark sobel gmc1_colinear_v1 1000"
                    </dt><dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark2.tar.gz"> Benchmark 2</a> - Use with either gmc1_colinear_v1 or gmc_colinear_v2 and around 1000 edges. Example use: "gmc_benchmark sobel gmc1_colinear_v1 1000"
                    </dt><dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark3.tar.gz"> Benchmark 3</a> - Use with either gmc1_colinear_v1 or gmc_colinear_v2 and around 1000 edges. Example use: "gmc_benchmark sobel gmc1_colinear_v1 1000"
                    </dt><dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark4.tar.gz"> Benchmark 4</a> - Use with gmc2_intersection and around 1500 edges. Example use: "gmc_benchmark sobel gmc2_intersection 1500"
                    </dt><dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark5.tar.gz"> Benchmark 5</a> - Use with gmc2_intersection and around 1500 edges. Example use: "gmc_benchmark sobel gmc2_intersection 1500"
                    </dt><dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark6.tar.gz"> Benchmark 6</a> - Use with gmc3_coparallel and around 3000 edges. Example use: "gmc_benchmark sobel gmc3_coparallel 3000"
                    </dt><dt><a href="https://www1.cs.columbia.edu/CAVE/software/edge/benchmark7.tar.gz"> Benchmark 7 </a> - Use with gmc4_ellipse3, gmc4_ellipse4, or gmc_ellipse5 and around 2000 edges. Example use: "gmc_benchmark sobel gmc4_ellipse3 2000"
                  </dt></dl>
                </dl>
                <p> The images which comprise each benchmark are in the images directory, tmp is used as a temporary workspace, and the results are placed in results.
  
              </p><p>
                </p><hr width="100%">
                <p class="style3"><u>Edge Detector Code</u></p>
                <p>The code for the edge detectors that I tested is contained <a href="https://www1.cs.columbia.edu/CAVE/software/edge/detectors.tar.gz">here</a>. Getting this to compile may be substantially more difficult than the evaluation code above. But, you are welcome to try. Once you have downloaded the code and uncompressed it, the first thing you need to do is edit the "BASEDIR" in the Makeconf file. Then try "make install." If all goes well, first add the appropriate subdirectory of bin to your path. </p>
                <p> Then, there is just one more thing you need to do before you can run the executables in bin/ops_sys. You need to generate the data for the Baker-Nayar-Murase detector. Change directory to the "features" directory and run: "generate -o se.pf -f Step_Edge -w Square_5x5 -s Rectangular_Averaging -n 50000 -d 8". Assuming everything has compiled OK, and you have set your path correctly, this will generate a step edge detector. The other executables have been hard-coded to find the file $BASEDIR/features/se.pf so do not move the directory or touch the file se.pf.
  
</p><p> There are 5 detectors: pfd (Baker-Nayar-Murase), nalwa (Nalwa-Binford), canny (a simple canny-like detector), sobel, and roberts_cross. All of them need to be appended with one of the following three appendixes: _nms (non-maximal suppression), _nms_spl (non-maximum suppression and sub-pixel localization), or _raw (neither nms or spl).
  

</p><p>
</p><hr width="100%">
<p><u class="style3">To Be Added</u>
 </p>
 <p> There are two things from the paper that I have yet to add to this page:
  
</p><dl>
  <dl>
    <dt>(1) The results I obtained for the detector I implemented.
        </dt><dt>(2) The scripts and the data to produce graphs like that in Figure 7 where a single detector is compared across equivalent datasets captured under different conditions.
      </dt></dl>
</dl>
I hope to find time to add these components as soon as possible. </div>
            </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p class="style3"><u>Contact Information</u>                  </p>
                  <p> If you encounter any problems trying to use my code, please contact me and I will try to help. E-mail: <a href="mailto:simonb@cs.cmu.edu">simonb@cs.cmu.edu</a> <span class="style1"><!--webbot
bot="Timestamp" i-checksum="14106" endspan --></span>
                  </p></div>
            </div></td>
          </tr>
        </tbody></table>    
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>